import React from "react";
import { graphql } from "gatsby";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  IndexBodyFragment,
  HeroSectionFragment,
  PopupFragment,
  LogoFragment,
  AdressFragment,
  CoursesFragment,
} from "../fragments";

import { Hero, Modal, Navigation, Sections } from "../components";

import { DefaultLayout } from "../layouts/defaultlayout";

const menu = [
  { title: "Über mich", path: "#uebermich" },
  { title: "Yoga", path: "#wasistyoga" },
  { title: "Kosten", path: "#praeventionskurs" },
  { title: "Wichtiges", path: "#wichtiges" },
];

export const query = graphql`
  query {
    hero: directus {
      ...HeroSectionFragment
    }
    modal: directus {
      ...PopupFragment
    }
    logo: directus {
      ...LogoFragment
    }
    adress: directus {
      ...AdressFragment
    }
    indexbody: directus {
      ...IndexBodyFragment
    }
    courses: directus {
      ...CoursesFragment
    }
    coursestext: directus {
      ...CoursestextFragment
    }
  }
`;

// eslint-disable-next-line import/no-default-export
export default ({ data }: any) => {
  return (
    <DefaultLayout>
      <Modal
        title={data.modal.popup.title}
        text={data.modal.popup.text}
        buttonYes={data.modal.popup.confirmation}
        isModalEnabled={data.modal.popup.enabled}
      />
      <Hero
        backgroundImage={data.hero.herosection.picture.imageUrl}
        anchor="uebermich"
      />
      <Navigation
        title="Anjawirth"
        menu={menu}
        logo={data.logo.logo.logo.imageUrl}
      />
      <Sections
        adress={data.adress.adress}
        sectionlist={data.indexbody.indexbody}
        courses={data.courses.courses}
        coursestext={data.coursestext.coursestext}
      />
    </DefaultLayout>
  );
};
